<template>
<h1 class="text-center">jquery.gsap.js</h1>
</template>

<script>
export default {
  name: "jquery.gsap.js"
}
</script>

<style scoped>

</style>